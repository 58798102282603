.button{
  &.button-primary{
    color: #ffffff;
    background: #965CB8;
    box-shadow: #324832 0 2px 0 0;
    padding: 10px 16px 8px 16px;
    border-radius: 6px;
    text-decoration: none;
    &:hover{
      background: #ab82b8;
    }
  }
}

.button-in-page {
  margin: 10px 0px;
  display: inline-block;
}
